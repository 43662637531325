
import { defineComponent } from "vue-demi";
import OrderMain from "@/components/admin/order/index.vue";

export default defineComponent({
  name: "OrderView",
  components: {
    OrderMain,
  },
});
