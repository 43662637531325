
import { defineComponent } from "vue-demi";
import CommonTable from '@/components/common/table/index.vue';
import { ADMIN_ORDER_TABLE_OPTIONS } from "@/store/common/table";

export default defineComponent({
  name: 'AdminOrderMain',
  data() {
    return {
      options: ADMIN_ORDER_TABLE_OPTIONS(),
    }
  },
  components: {
    CommonTable
  }
})
